<template>
  <div class="card h-100 mt-4">
    <div class="card-header pb-0 p-3 invoice">
      <div class="row">
          <!-- Invoice -->
          <div v-if="invoice_popup" class="col-12">
            
              <div id="myModal" class="modal">
                  <div class="modal-content" data-bs-theme="dark">
                    <span @click="popup_close" class="close text-right">&times;</span>
                      <div class="col-12">
                          <div id="invoice" class="invoice p-6 align-items-center" ref="invoice" style="background-color: white !important; color: black !important;">
                              <div class="invoice-header d-flex justify-content-between">                                
                                <div class="d-flex">
                                  <img :src="logo" class="navbar-brand-img rounded-circle" style="height:100px" alt="main_logo" />
                                  <h1 class="px-3">OLT Care</h1>
                                </div>
                                <div>                                  
                                  <h2 class="invoice-title">Invoice</h2>
                                  <p class="invoice-number" style="color: black !important;">Invoice Number: {{invoice_data.invoice_number}}</p>
                                  <p class="invoice-date" style="color: black !important;" v-if="invoice_data.is_paid ">Payment Status: Paid</p>
                                </div>
                                  
                              </div>
                              <div class="invoice-body">
                                  <table class="table">
                                      <thead>
                                          <tr>
                                              <th style="color: black !important;">Package</th>
                                              <th style="color: black !important;">Cycle Type</th>
                                              <th style="color: black !important;">Expire Date</th>
                                              <th style="color: black !important;">Total</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr>
                                              <td style="color: black !important;">{{invoice_data.olt_pack}}</td>
                                              <td style="color: black !important;">{{invoice_data.cycle_type}}</td>
                                              <td style="color: black !important;">{{invoice_data.expiredate}}</td>
                                              <td style="color: black !important;">{{invoice_data.total_amount}} Tk</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <!-- <div class="invoice-footer">
                                  <p class="invoice-total">Total: $40.00</p>
                              </div> -->
                          </div>
                          <button class="btn-success d-flex justify-item-center" @click="generatePDF">Generate PDF</button>
                      </div>
                  </div>
              </div>
          </div>
        <!-- Invoice -->
        <div class="col-12 d-flex align-items-between">
          
        <div class="col-8 d-flex align-items-between">
          <h6 class="mb-0">Invoices</h6>
        </div>
          <select @change="handleOltData()" v-model="filter"  class="form-control invoice dark-modeon" name="filter" id="filter">
                        <option   value="" selected disabled hidden>Filter</option>
                        <option   value="">All</option>
                        <option   value="paid">Paid</option>
                        <option   value="due">Due</option>
                        <!-- <option v-for="item in filter_port_olt"  :key="item.OLT_NAME" :value="item.OLT_NAME">{{item.OLT_NAME}}</option> -->
                    </select>
        </div>
        <div class="col-6 text-end">
          <div v-if="showPaymentMethod" id="myModal" class="modal">
          <div class="modal-content dark-modeon" data-bs-theme="dark">
            <span @click="popup_close" class="close">&times;</span>

            <h5 class="text-center">Please Select Payment Method</h5>

            <div class="my-3 mx-12 text-center">
            <p v-if="pay_loading">Loading ..</p>
            <span v-if="pay_loading" class="spinner-border spinner-border-sm mx-3"></span>
             <span v-else @click="getPay()" class="cursor-pointer"><img src="../../assets/img/logos/images.png" style="width:200px" /></span>
            </div>
            <div class="flex text-center">
            
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="card-body p-3 pb-0 mb-0 rounded-0 invoice">
      <ul class="list-group">
        <li v-for="(item,index) in filteredData" :key="index"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg invoice"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{item.olt_pack}} 
              <sup>
              <soft-badge v-if="item.is_paid == true" color="success" variant="gradient" size="xl">
                Paid
              </soft-badge>
              <soft-badge v-else-if="item.is_paid == false" color="danger" variant="gradient" size="xl">
                Due
              </soft-badge>
            </sup>

            </h6>
            <span class="text-xs">#{{item.invoice_number}}</span>
          </div>
          <div class="d-flex align-items-center text-sm">
           <span> Tk {{item.total_amount}}</span>
              <soft-badge @click="Pay(item.invoice_number)" class="ms-3 cursor-pointer" v-if="item.is_paid == false" color="dark" variant="gradient">
              Pay
            </soft-badge>
            <button v-else @click="show_invoice_popup(item)" class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
              <i class="fas fa-file-pdf text-lg me-1" aria-hidden="true"></i>
              PDF
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import SoftButton from "@/components/SoftButton.vue";
import logo from "@/assets/img/logos/logo.jpg";
import SoftBadge from "@/components/SoftBadge.vue";
//Service 
import invoiceList from "../../services/get-user-invoice-list.service";
import pay from "../../services/get-pay-bill.service";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
  name: "InvoiceCard",
  components: {
    // SoftButton,
    SoftBadge
  },

  mounted() {
    this.getData();
  },

  data() {
      return {
        logo,
        filter:'',
        pay_loading:false,
        invoice_popup:false,
        invoice_data:[],
        showPaymentMethod:false,
        invoice:'',
          sortKey: '',
          sortOrder: 'asc',
          data: [],
          filteredData: [],
          searchQuery: "",
          currentPage: 1,
          itemsPerPage: 20
      };
  },

  methods: {
    show_invoice_popup(item){
      this.invoice_popup=true;
      this.invoice_data =item;

    },
    generatePDF() {
      // Get the invoice element
      const invoice = this.$refs.invoice;

      html2canvas(invoice).then((canvas) => {
        // Convert canvas to an image
        const imgData = canvas.toDataURL('image/png');

        // Calculate PDF height and width
        const pdf = new jsPDF({
          orientation: 'landscap',
          unit: 'px',
          format: [500, 500]
        });

        // Add image to PDF
        pdf.addImage(imgData, 'PNG', 0, 0);

        // Save PDF
        pdf.save('invoice.pdf');
      });
    },

    handleOltData() {
      if(this.filter == 'paid'){
        this.filteredData = this.data.filter(item => item.is_paid === true);
      }else if(this.filter == 'due'){
        this.filteredData = this.data.filter(item => item.is_paid === false);
      }else{
        this.filteredData = this.data
      }
    },

    popup_close(){
        this.showPopup = false
        this.invoice = null
        this.invoice_popup = null
        this.invoice_data = []
        this.showPaymentMethod =false
         
      },

    async Pay(Invoice_id){
        this.invoice = Invoice_id
        this.showPaymentMethod =true
      },
    async getPay(){        
        this.pay_loading = true
        let response = await pay.getPayBill(this.invoice)
        if(response){
          window.open(response.bkashURL);
        }
        this.showPaymentMethod =false
        
        this.pay_loading = false
      },
      async getData() {
          const response = await invoiceList.getUserInvoiceDataList();
          this.data = response;
          this.filteredData = response;
      },
  }
  
};
</script>


<style scoped>
/* The Modal (background) */
.modal { 
  display: block;/* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: #aaaaaa;
   /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow */
  max-width: 50%; /* Set max width */
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

</style>

