<template>
  <div class="chart-js-res d-flex gap-3">
    <canvas class="myChart charts-cards dark-modeon">
    </canvas>
  </div>
</template>

<script setup>
import { onMounted ,defineProps } from 'vue';
import Chart from 'chart.js/auto';
const { item:item } = defineProps(["chartId","item"]);

onMounted(() => {
  
  // Define chart data
  const labels = [`CPU  ${item.output_cpu?? 0}%`, `IDLE  ${100-item.output_cpu??0}%`,`Memory  ${item.output_mem}%`];
  let data = [item.output_cpu??0, 100-item.output_cpu??0]; // Initial data

  // Define background colors for each chart
  let chartColors = [
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
    ['#66ca99', '#eaeaea'], 
  ];

  if(item.output_cpu > 70){
    chartColors = [
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
      ['#EA0606', '#eaeaea'],
  ];
  }

  // Define chart plugins
  const align = 'center';
  const font = ' 16px Arial'; 

  // Loop through each canvas element and initialize the charts with different colors
  const chartElements = document.querySelectorAll('.myChart');
  chartElements.forEach((element, index) => {
    const ctx = element.getContext('2d');
    const bgColor = chartColors[index]; // Get colors for current chart
    const centerText = createCenterTextPlugin(font, align);
    const titlePlugin = createTitlePlugin();
    const config = createChartConfig(labels, data, bgColor, centerText, titlePlugin);

     if (element.chart) {
        element.chart.destroy();
      }

      // Create a new Chart.js instance
  element.chart = new Chart(ctx, config);
  });

  // Function to create the centerText plugin
  function createCenterTextPlugin(font, align) {
    return {
      afterDatasetsDraw: function(chart) {
        const { ctx, chartArea: { left, right, top, bottom } } = chart;
        //const total = data.reduce((acc, curr) => acc + curr, 0);
        const centerX = (left + right) / 2;
        const centerY = (top + bottom) / 2;

        ctx.save();
        ctx.font = font; // Applying bold font
        ctx.fillStyle = 'gray' ;
        ctx.textAlign = align;

        // Split the label into "Total" and "Percentage" parts
        const totalLabel = ' ';
        const totalFontWeight = 'Light'; // Set font weight for total label
        const percentageLabel = item.output_cpu + '%';
        const percentageFontWeight = 'bold'; // Set font weight for percentage label

        // Measure the width of the total label
        const totalWidth = ctx.measureText(totalLabel).width;

        // Calculate the x-coordinate for the percentage label to align it to the right
        const percentageX = centerX + totalWidth / 2;

        // Draw the total label with bold font weight
        ctx.font = `${totalFontWeight} ${font}`;
        ctx.fillText(totalLabel, centerX - totalWidth / 2, centerY);

        // Draw the percentage label with normal font weight
        ctx.font = `${percentageFontWeight} ${font}`;
        ctx.fillText(percentageLabel, percentageX, centerY);

        ctx.restore();
      }
    };
  }

  // Function to create the titlePlugin
  function createTitlePlugin() {
    return {
      beforeDraw: function(chart) {
        const ctx = chart.ctx;
        const chartArea = chart.chartArea;
        const width = chart.width;
        let title = item?.olt_name ?? '';
        let title2 = `Up-Time : ${item.uptime}`;


        ctx.save();
        ctx.fillStyle = 'gray';
        ctx.textBaseline = 'middle';
        ctx.font = '16px Arial';
        ctx.textAlign = 'center';

        // Draw title inside the chart
        ctx.fillText(title, (width / 2), (chartArea.top + chartArea.bottom) / 10); // Adjust Y position as needed
        ctx.fillText(title2, (width / 2), (chartArea.top + chartArea.bottom) / 2 +80); // Adjust Y position as needed

        ctx.restore();
      }
    };
  }

  // Function to create chart configuration
  function createChartConfig(labels, data, bgColor, centerText, titlePlugin) {
    return {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          label: 'My First Dataset',
          data: data,
          backgroundColor: bgColor,
          hoverOffset: 4,
          cutout: '75%',
          borderWidth: 0, // Set border width to 0
        }]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
        },
        layout: {
          padding: 0 // Set padding to 0
        }
      },
      plugins: [centerText, titlePlugin]
    };
  }
});
</script>

<style>
/* Add any necessary styles here */
</style>
