<template>

<!-- chart js  -->
<div class="py-4 container-fluid">
    <!-- chart js -->
    <div class="row">

        
        <div v-for="(item,index) in data.filter(item => item.show == true )" :key="index" class="col-lg-2 text-center">
            
                <Chart :item="item" />
                
        </div>
        
    </div>  <!-- chart js -->
</div>
    <div class="py-4 container-fluid">
        <div class="row">
            <!-- Payment Method -->
            <div class="col-6 text-end">
                <div v-if="showPaymentMethod" id="myModal" class="modal_class">
                <div class="modal-content invoice">
                    <span @click="popup_close" class="close">&times;</span>

                    <h5 class="text-center">Please Select Payment Method</h5>

                    <div class="my-3 mx-12 text-center">
                    <p v-if="pay_loading">Loading ..</p>
                    <span v-if="pay_loading" class="spinner-border spinner-border-sm mx-3"></span>
                    <span v-else @click="getPay()" class="cursor-pointer"><img src="../assets/img/logos/bkash.svg" style="width:200px" /></span>
                    </div>
                    <div class="flex text-center">
                    
                    </div>
                </div>
            </div>
            </div>
             <!--Month List Popup -->
                <div v-if="showPopup"  id="myModal" class="modal_class">
                    <div class="modal-content dark-modeon ">
                        <span @click="popup_close" class="close text-end">&times;</span>

                        <div class="my-3 mx-12 mx-auto">
                        <default-info-card class="cursor-pointer"
                                icon="text-white fas fa-shopping-cart"
                                :title="pact_data.olt_packge_list[0].name"
                                :port_count="pact_data.olt_packge_list[0].port_count"
                                :olt_modes="pact_data.olt_packge_list[0].olt_modes_id"
                                :value="'Tk '+(firstIntegerValue?firstIntegerValue*pact_data.olt_packge_list[0].price: pact_data.olt_packge_list[0].price)"
                                :expeire_at="firstIntegerValue?formatDate(new Date(new Date().setMonth(new Date().getMonth() + firstIntegerValue))):null"
                                />
                                <select v-model="selected_month" name="" id="" class="form-control mt-3 text-black font-bold text-center bg-white">
                                    <option value="" disabled selected hidden>Select Month</option>
                                    <option  v-for="(item,index) in month_data" :key="index" :value="index">{{item}}</option>
                                </select>
                                </div>
                                <div class="flex text-center">
                                <div class="grid grid-cols-2 gap-2">
                                <button class="btn btn-secondary mx-3" @click="popup_close">Cancel</button>
                                <button class="btn btn-success" @click="Pay(selected_month)">Pay Now <span v-if="loading_pay_now" class="spinner-border spinner-border-sm mx-3"></span></button>
                            </div>
                                </div>
                            </div>
                    </div>




            <div class="col-12">
                <div class="card invoice">
                    <!-- Card header -->
                    <div class="pb-0 card-header invoice">
                        <div class="d-lg-flex justify-content-between mx-3">
                            <div>
                                <h5> Olt List <span class="badge">{{filteredData.length}}</span></h5>

                            </div>
                            <div class="d-flex gap-3">
                                <div class="flex-grow-1">
                                    <router-link :to="{ name: 'AddOlt' }">
                                        <button class="btn btn-success">Add New</button>
                                    </router-link>
                                </div>
                                <div class="flex-grow-1">
                                    
                                    <input type="text" v-model="searchQuery" class="form-control invoice"
                                        placeholder="Search...">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="px-0 pb-0 card-body">
                        <div class="table-responsive">
                            <div class="card mb-4">
                                <div class="card-body px-0 pt-0 pb-2 invoice">
                                    <div class="table-responsive p-0">
                                        <table class="table align-items-center mb-0">
                                            <thead>
                                                <tr>
                                                    <th @click="sort('show')" :class="{ 'asc': sortKey === 'show' && sortOrder === 'asc', 'desc': sortKey === 'show' && sortOrder === 'desc' }"
                                                        class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        Expire Status
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('id')" :class="{ 'asc': sortKey === 'id' && sortOrder === 'asc', 'desc': sortKey === 'id' && sortOrder === 'desc' }" 
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        ID
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('Mikrotik_ip')" :class="{ 'asc': sortKey === 'Mikrotik_ip' && sortOrder === 'asc', 'desc': sortKey === 'Mikrotik_ip' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        Mikrotik IP
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('m_username')" :class="{ 'asc': sortKey === 'm_username' && sortOrder === 'asc', 'desc': sortKey === 'm_username' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        M User Name
                                                    <span class="sort-icon"> </span></th>
                                                    
                                                    <th @click="sort('m_port')" :class="{ 'asc': sortKey === 'm_port' && sortOrder === 'asc', 'desc': sortKey === 'm_port' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        M Port
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('olt_ip')" :class="{ 'asc': sortKey === 'olt_ip' && sortOrder === 'asc', 'desc': sortKey === 'olt_ip' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        OLT IP
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('olt_username')" :class="{ 'asc': sortKey === 'olt_username' && sortOrder === 'asc', 'desc': sortKey === 'olt_username' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        OLT User Name
                                                    <span class="sort-icon"> </span></th>
                                                    
                                                    <th @click="sort('olt_port')" :class="{ 'asc': sortKey === 'olt_port' && sortOrder === 'asc', 'desc': sortKey === 'olt_port' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        OLT Port
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('description')" :class="{ 'asc': sortKey === 'description' && sortOrder === 'asc', 'desc': sortKey === 'description' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        Description
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('olt_name')" :class="{ 'asc': sortKey === 'olt_name' && sortOrder === 'asc', 'desc': sortKey === 'olt_name' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        Olt Name
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('next_update_time')" :class="{ 'asc': sortKey === 'next_update_time' && sortOrder === 'asc', 'desc': sortKey === 'next_update_time' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        Expire Date
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('system_name')" :class="{ 'asc': sortKey === 'system_name' && sortOrder === 'asc', 'desc': sortKey === 'system_name' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        System Name
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('another_mem')" :class="{ 'asc': sortKey === 'another_mem' && sortOrder === 'asc', 'desc': sortKey === 'another_mem' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        Another Mem
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('olt_billing')" :class="{ 'asc': sortKey === 'olt_billing' && sortOrder === 'asc', 'desc': sortKey === 'olt_billing' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        Olt Billing
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('olt_modes')" :class="{ 'asc': sortKey === 'olt_modes' && sortOrder === 'asc', 'desc': sortKey === 'olt_modes' && sortOrder === 'desc' }"
                                                        class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                        Olt Modes
                                                    <span class="sort-icon"> </span></th>
                                                    <th @click="sort('olt_brands')" :class="{ 'asc': sortKey === 'olt_brands' && sortOrder === 'asc', 'desc': sortKey === 'olt_brands' && sortOrder === 'desc' }"
                                                        class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                        Olt Brands
                                                    <span class="sort-icon"> </span></th> 
                                                    <th class="text-secondary opacity-7"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(data,index) in filteredData" :key="index">
                                                    <td class="align-middle text-center">
                                                        <soft-badge @click="open_popup(data.id)" v-if="data.show == false" class="bg-danger p-1 rounded-1 text-white cursor-pointer text-sm" variant="gradient" size="sm">Renew Now
                                                        </soft-badge>

                                                        <soft-badge v-if="data.show == true" class="btn-success p-1 rounded-1 text-white cursor-pointer text-sm" variant="gradient" size="sm">Active
                                                        </soft-badge>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}">
                                                        <div class="d-flex px-2 py-1">
                                                            {{data.id}}
                                                        </div>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center text-sm">
                                                        <p class="text-xs font-weight-bold mb-0">{{data.Mikrotik_ip}}</p>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center text-sm">                                                        
                                                        <p class="text-xs font-weight-bold mb-0">{{data.m_username}}</p>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.m_port}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.olt_ip}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span class="text-xs font-weight-bold mb-0">{{data.olt_username}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.olt_port}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.description}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.olt_name}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data?.olt_billing_expiredate}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.system_name}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.another_mem}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.olt_billing}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.olt_modes}}</span>
                                                    </td>
                                                    <td :class="{'text-danger': !data.show}" class="align-middle text-center">
                                                        <span
                                                            class="text-xs font-weight-bold">{{data.olt_brands}}</span>
                                                    </td>
                                                    <!-- <td class="align-middle">
                                                        <router-link :to="{name:'EditOlt', params: { 'edit_id': data.id }}"
                                                            class="text-secondary font-weight-bold text-xs"
                                                            data-toggle="tooltip"
                                                            data-original-title="Edit user">Edit</router-link>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="pagination-container">
                            <button class="btn btn-sm btn-success mx-3 mt-3" @click="previousPage"
                                :disabled="currentPage === 1">Previous</button>
                            <span class="mx-2">Page {{ currentPage }} of {{ totalPages }}</span>
                            <button class="btn btn-sm btn-success mx-3 mt-3" @click="nextPage"
                                :disabled="currentPage === totalPages">Next</button>
                        </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
    import dataList from "../services/data_list.service";
    import Chart from "@/components/Chart.vue";
    import MonthList from "../services/get-month-list.service";
    import FasterPayBill from "../services/faster-pay-bill.service";
    import PayBillByPacID from "../services/get-pack-name-with-olt-id.service";
    import showSwal from "../mixins/showSwal.js";

    export default {
        name: "Olt List",
        components: {
            DefaultInfoCard, Chart
        },
        data() {
            return {
                sortKey: '',
                sortOrder: 'asc',
                data: [],
                pact_data: [],
                filteredData: [],
                month_data: [],
                selected_month:'',
                selected_id:null,
                searchQuery: "",
                currentPage: 1,
                itemsPerPage: 20,
                showPopup:false,
                bkashURL:'',
                pay_loading:false,
                loading_pay_now:false,
                showPaymentMethod:false
            };
        },
        async mounted() {
            await this.getData();
            await this.getMonthData();
        },
        methods: {
            formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
            async Pay(selected_month){
                this.loading_pay_now=true
                if(selected_month == ''){
                showSwal.methods.showSwal({
                        type: "error",
                        message: 'Please Select a Month',
                    });
                }
                const response = await FasterPayBill.PayBill(selected_month,this.selected_id);
                this.loading_pay_now=false
                if(response.bkashURL){
                this.bkashURL = response.bkashURL
                this.showPaymentMethod =true
                this.showPopup = false
                }else{
                showSwal.methods.showSwal({
                        type: "error",
                        message: 'Something is wrong',
                    });
                }
            },

            async getPay(){  
                this.pay_loading = true
                if(this.bkashURL){
                window.open(this.bkashURL);
                }
                this.showPaymentMethod =false
                this.pay_loading = false
            },

            popup_close(){
                this.showPopup = false
                this.showPaymentMethod = false
                this.selected_item=[],
                this.selected_id=null,
                this.selected_month='',
                this.pact_data=[]
            },

            async open_popup(id){
                this.pact_data = await PayBillByPacID.PayBillByPacID(id)
                this.showPopup = true
                this.selected_id = id
            },
             async getMonthData() {
                const response = await MonthList.getMonthListData();
                this.month_data = response;
            },
            async getData() {
                const response = await dataList.getDataList();
                this.data = response;
                this.filteredData = response;
            },
            sort(key) {
                if (this.sortKey === key) {
                    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
                } else {
                    this.sortKey = key;
                    this.sortOrder = 'asc';
                }

                this.filteredData.sort((a, b) => {
                    const valueA = a[key] ? a[key].toString().toLowerCase() : '';
                    const valueB = b[key] ? b[key].toString().toLowerCase() : '';
                    const comparison = valueA.localeCompare(valueB);
                    return this.sortOrder === 'asc' ? comparison : -comparison;
                });
            },
            previousPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                }
            }
        },
        computed: {
            totalPages() {
                return Math.ceil(this.filteredData.length / this.itemsPerPage);
            },
            paginatedData() {
                const startIndex = (this.currentPage - 1) * this.itemsPerPage;
                return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
            },
            firstIntegerValue() {
            const match = this.selected_month.match(/\d+/);
            return match ? parseInt(match[0]) : null;
            }
        },
        watch: {
            searchQuery(newValue) {
                this.filteredData = this.data.filter(item => {
                    for (let key in item) {
                        if (String(item[key]).toLowerCase().includes(newValue.toLowerCase())) {
                            return true;
                        }
                    }
                    return false;
                });
                // Reset currentPage when searchQuery changes
                this.currentPage = 1;
            }
        }
    };
</script>

<style>

/* The Modal (background) */
.modal_class { 
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background: #aaaaaa;
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff; 
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  max-width: 50%; 
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Close Button */
    .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    }

    .close:hover,
    .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    }



    td {
        padding: 12px 24px !important;
    }

    .sort-icon {
        margin-left: 5px;
    }

    .asc::after {
        content: "▲";
    }

    .desc::after {
        content: "▼";
    }


    .badge {
        display: inline-block;
        padding: 0.25em 0.4em;
        font-size: 75%;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 50%;
        background-color: #40C133;
        /* or any other color you prefer */
        color: #fff;
        /* text color */
    }


</style>