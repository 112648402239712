<template>

  <div class="py-4 container-fluid" v-if="dashoard_data">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4 ">
        <mini-statistics-card
          title="OLT"
          :value="dashoard_data?.olt ?? 0"
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="OLT PORT"
          :value="dashoard_data?.olt_Port ?? 0"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <mini-statistics-card
          title="OLT 28+ DB"
          :value="dashoard_data?.olt_28db ?? 0"
          :percentage="{
            value: '-2%',
            color: 'text-danger',
          }"
          :icon="{
            component: 'ni ni-money-coins',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0">
        <mini-statistics-card
          title="OLT OFFLINE ONU"
          :value="dashoard_data?.olt_offline_onu ?? 0"
          :percentage="{
            value: '+5%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-world',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
    </div>


    <div class="mt-4 row" v-if="dashoard_data?.db_list">
      <div class="mb-4 col-lg-5 mb-lg-0" >
  <div class="card z-index-2">
    <div class="p-3 card-body invoice-card" style="min-height:400px">
      <h4 class="font-weight-bolder">DB List</h4>

      <div class="mt-3">
        <div class="row justify-content-center">
          <div  class="col-md-6 px-6 mb-3 text-center" >
            <div class="d-flex text-center m-3 ">
              <div class="text-center shadow icon icon-shape icon-xxs border-radius-sm me-2" :class="`bg-gradient-${dashoard_data?.db_list.dblist_db.color_code}`">
                <font-awesome-icon icon="faUsers" size="xs" style="color: white;"></font-awesome-icon>
              </div>
              <p class="text-uppercase">{{dashoard_data?.db_list.dblist_db.dbname}}</p>
            </div>
            <p class="mb-0 text-xs font-weight-bold"><b class="text-lg">{{dashoard_data?.db_list.dblist_db.db_count ?? 0}}</b></p>
            <div class="progress w-100 mt-3">
              <div class="progress-bar bg-dark" :class="`w-${Math.ceil(dashoard_data?.db_list.dblist_db.db_count / 5) * 5}`" role="progressbar" :aria-valuenow="data" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div  class="col-md-6 px-6 mb-3 text-center" >
            <div class="d-flex text-center m-3 ">
              <div class="text-center shadow icon icon-shape icon-xxs border-radius-sm me-2"  :class="`bg-gradient-${dashoard_data?.db_list.dblist_db1.color_code}`">
                <font-awesome-icon icon="faUsers" size="xs" style="color: white;"></font-awesome-icon>
              </div>
              <p class="text-uppercase">{{dashoard_data?.db_list.dblist_db1.dbname}}</p>
            </div>
            <p class="mb-0 text-xs font-weight-bold"><b class="text-lg">{{dashoard_data?.db_list.dblist_db1.db_count ?? 0}}</b></p>
            <div class="progress w-100 mt-3">
              <div class="progress-bar bg-dark" :class="`w-${Math.ceil(dashoard_data?.db_list.dblist_db1.db_count / 5) * 5}`" role="progressbar" :aria-valuenow="data" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div  class="col-md-6 px-6 mb-3 text-center" >
            <div class="d-flex text-center m-3 ">
              <div class="text-center shadow icon icon-shape icon-xxs border-radius-sm me-2" :class="`bg-gradient-${dashoard_data?.db_list.dblist_db2.color_code}`">
                <font-awesome-icon icon="faUsers" size="xs" style="color: white;"></font-awesome-icon>
              </div>
              <p class="text-uppercase">{{dashoard_data?.db_list.dblist_db2.dbname}}</p>
            </div>
            <p class="mb-0 text-xs font-weight-bold"><b class="text-lg" >{{dashoard_data?.db_list.dblist_db2.db_count ?? 0}}</b></p>
            <div class="progress w-100 mt-3">
              <div class="progress-bar bg-dark" :class="`w-${Math.ceil(dashoard_data?.db_list.dblist_db2.db_count / 5) * 5}`" role="progressbar" :aria-valuenow="data" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div  class="col-md-6 px-6 mb-3 text-center" >
            <div class="d-flex text-center m-3 ">
              <div class="text-center shadow icon icon-shape icon-xxs border-radius-sm me-2" :class="`bg-gradient-${dashoard_data?.db_list.dblist_db3.color_code}`">
                <font-awesome-icon icon="faUsers" size="xs" style="color: white;"></font-awesome-icon>
              </div>
              <p class="text-uppercase">{{dashoard_data?.db_list.dblist_db3.dbname}}</p>
            </div>
            <p class="mb-0 text-xs font-weight-bold"><b class="text-lg">{{dashoard_data?.db_list.dblist_db3.db_count ?? 0}}</b></p>
            <div class="progress w-100 mt-3">
              <div class="progress-bar bg-dark" :class="`w-${Math.ceil(dashoard_data?.db_list.dblist_db3.db_count / 5) * 5}`" role="progressbar" :aria-valuenow="data" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      <div class="col-lg-7" >
        <!-- line chart -->
        <div class="card z-index-2 invoice-card" v-if="chartData" style="height:400px">
          <gradient-line-chart
            id="chart-line"
            title="Gradient Line Chart"
            description="<i class='fa fa-arrow-up text-success'></i>
      <span class='font-weight-bold'>OLT Care Chart </span>"
            :chart="chartData"
          />
        </div>
      </div>
    </div>
    <div class="row" data-bs-theme="dark">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4 mt-4">
        <projects-card :dashoard_olt_info_data="dashoard_olt_info_data" />
      </div>
      <div class="col-lg-4 col-md-6">
        <InvoiceCard />
        <!-- <timeline-list
          class="h-100 "
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            color="success"
            icon="bell-55"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="danger"
            icon="html5"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="cart"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="credit-card"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="key-25"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
          />
          <TimelineItem
            color="info"
            icon="check-bold"
            title="Notifications unread"
            date-time="15 DEC"
          />
        </timeline-list> -->
      </div>
    </div>

    <!-- hello -->
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import InvoiceCard from "./components/InvoiceCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
/* import TimelineList from "./components/TimelineList.vue";
import TimelineItem from "./components/TimelineItem.vue"; */
import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import getDashboardData from "../services/dashboard.service";
import homePageOltInfoList from "../services/homePageOltInfoList.service";
import getDashboardChartData from "../services/get-dashboard-chart.service";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      description_data: [],
      count_data: [],
      chartData: null,
      previousIndex: -1,
      dashoard_data:[],
      dashoard_olt_info_data:[],
      dashoard_chart_data:[],
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  components: {
    MiniStatisticsCard,
    // ReportsBarChart,
    GradientLineChart,
    ProjectsCard,
    /* TimelineList,
    TimelineItem, */
    InvoiceCard
  },

  async mounted() {
            this.getDashboardData();
            this.getDashboardChartData();
        },
  methods: {
    async getDashboardData() {
      try {
        this.dashoard_data = await getDashboardData.getDashboardData();
        this.dashoard_olt_info_data = await homePageOltInfoList.getOltHomePageData();
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    },
    async getDashboardChartData() {
      try {
        this.dashoard_chart_data = await getDashboardChartData.getDashboardChartData();
        
        // Process the data
        this.count_data = this.dashoard_chart_data?.map(item => Number(item.count));
        this.description_data = this.dashoard_chart_data?.map(item => Number(item.description));

        // Prepare chart data
        this.chartData = {
          labels: this.dashoard_chart_data?.map((item, index) => index.toString()),
          datasets: [
            {
              label: 'Description',
              data: this.description_data,
            },
            {
              label: 'Count',
              data: this.count_data,
            },
          ],
        };
      } catch (error) {
        console.error('Error fetching dashboard chart data:', error);
      }
    }

  }
};


// dark mode



</script>

<style scoped>
.col-lg-4.col-md-6 {
  overflow-y: auto; 
}

.col-lg-4.col-md-6::-webkit-scrollbar {
  display: none;
}
</style>



