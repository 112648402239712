import axios from "axios";

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async PayBill(selected_month,id) {
      try {
        const response = await axios.get(API_URL + `/api/olt-fast-pay/?cycle_type=${selected_month}`,{
            headers: {
              'Authorization': id
            }
          });
        return response.data;
      } catch (err) {
        console.error("Error:", err);
        throw err; 
      }
    },
  };
