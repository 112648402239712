<template>
  <aside
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
    :class="[isRTL ? 'me-3 rotate-caret' : 'ms-3']"
    id="sidenav-main"
  >
    <div>
      <div class="sidenav-header">
      
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <div class="d-flex ">
        <a class="m-0 navbar-brand" href="/">
        <img :src="logo" class="navbar-brand-img h-100 rounded-circle" alt="main_logo" />
        <span class="ms-1 font-weight-bold">OLT Care</span>
      </a>


        <a
              href="#"
              @click="toggleSidebar"
              class="pt-3 nav-link text-body"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </a>
      </div>
      
    </div>
    <hr class="mt-0 horizontal dark"  />
    <sidenav-list  :cardBg="customClass" />
    </div>
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import logo from "@/assets/img/logos/logo.jpg";
import { mapState } from "vuex";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
      darkMode:false
    };
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const savedDarkMode = localStorage.getItem('darkMode');
    if (savedDarkMode !== null) {
      this.darkMode = JSON.parse(savedDarkMode); // Update dark mode state
    }
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      if(this.darkMode == false){
        this.toggleSidebarColor("bg-white");
      }else{
        this.toggleSidebarColor("bg-dark");
      }
      this.navbarMinimize();
    },
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
<style scoped>
.rounded-circle {
    border-radius: 50%;
}
</style>
