

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import "../src/dark-mode.js";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/custom.css";
import "./assets/css/nucleo-svg.css";

import SoftUIDashboard from "./soft-ui-dashboard";


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");


// Get references to the checkbox and the sun/moon icons
const toggleSwitch = document.getElementById('checkbox');
const sunIcon = document.querySelector('.sun-icon');
const moonIcon = document.querySelector('.moon-icon');

// Function to toggle theme and icons
function switchTheme(e) {
  if (e.target.checked) {
    // Dark mode
    document.documentElement.setAttribute('data-theme', 'dark');
    document.body.classList.add('dark-mode');
    localStorage.setItem('theme', 'dark');
    // Show moon icon and hide sun icon
    moonIcon.style.display = 'block';
    sunIcon.style.display = 'none';
  } else {
    // Light mode
    document.documentElement.setAttribute('data-theme', 'light');
    document.body.classList.remove('dark-mode');
    localStorage.setItem('theme', 'light');
    // Show sun icon and hide moon icon
    sunIcon.style.display = 'block';
    moonIcon.style.display = 'none';
  }
}

// Add event listener to toggle switch
toggleSwitch.addEventListener('change', switchTheme, false);

// Check the initial theme and set the icon accordingly
const currentTheme = localStorage.getItem('theme');
if (currentTheme === 'dark') {
  moonIcon.style.display = 'block';
  sunIcon.style.display = 'none'; // Hide sun icon initially
} else {
  sunIcon.style.display = 'block';
  moonIcon.style.display = 'none'; // Hide moon icon initially
}
