<template>
  <div class="row mt-4">
    <!-- Payment Method -->
    <div class="col-6 text-end  col-sm-6">
          <div v-if="showPaymentMethod" id="myModal" class="modal">
          <div class="modal-content dark-modeon">
            <span @click="popup_close" class="close">&times;</span>

            <h5 class="text-center">Please Select Payment Method</h5>

            <div class="my-3 mx-12 text-center">
            <p v-if="pay_loading">Loading ..</p>
            <span v-if="pay_loading" class="spinner-border spinner-border-sm mx-3"></span>
             <span v-else @click="getPay()" class="cursor-pointer"><img src="../../assets/img/logos/bkash.svg" style="width:200px" /></span>
            </div>
            <div class="flex text-center">
            
            </div>
          </div>
        </div>
        </div>

    <!--Month List Popup -->
      <div v-if="showPopup" id="myModal" class="modal ">
      <div class="modal-content dark-modeon card-witdh">
        <span @click="popup_close" class="close">&times;</span>

        <div class="my-3 mx-12 mx-auto">
          <default-info-card @click="popup_open(selected_item)" class="cursor-pointer"
                  icon="text-white fas fa-shopping-cart"
                  :title="selected_item.name"
                  :port_count="selected_item.port_count"
                  :olt_modes="selected_item.olt_modes"
                  :value="'Tk '+(firstIntegerValue?firstIntegerValue*selected_item.price: selected_item.price)"
                  :expeire_at="firstIntegerValue?formatDate(new Date(new Date().setMonth(new Date().getMonth() + firstIntegerValue))):null"
                />
          <select v-model="selected_month" name="" id="" class="form-control mt-3 text-black font-bold text-center bg-white">
            <option value="" disabled selected hidden>Select Month</option>
            <option  v-for="(item,index) in month_data" :key="index" :value="index">{{item}}</option>
          </select>
        </div>
        <div class="flex text-center">
        <div class="grid grid-cols-2 gap-2">
          <button class="btn btn-secondary mx-3" @click="popup_close">Cancel</button>
          <button class="btn btn-success" @click="Pay(selected_item.id,selected_month)">Pay Now <span v-if="loading_pay_now" class="spinner-border spinner-border-sm mx-3"></span></button>
          <button class="btn btn-info mx-3" @click="addToCart">Pay Latter <span v-if="loading_pay_letter" class="spinner-border spinner-border-sm mx-3"></span></button>
        </div>
        </div>
      </div>
    </div>



          <div class="col-xl-12">
            <div class="row">
              <div v-for="(item,index) in data" :key="index" class="col-md-4 py-3">
                <default-info-card @click="popup_open(item)" class="cursor-pointer"
                  icon="text-white fas fa-shopping-cart"
                  :title="item.name"
                  :port_count="item.port_count"
                  :olt_modes="item.olt_modes"
                  :value="'Tk '+item.price"
                />
              </div>
            </div>
          </div>
         <!-- <PaymentMethodsCard/> -->
        </div>
</template>
<script>

import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
//Service 
import packageList from "../../services/get-package-list.service";
import MonthList from "../../services/get-month-list.service";
import Invoice from "../../services/post-generate-invoice.service";
import payNow from "../../services/generate-invoice-pay-now.service";
import pay from "../../services/get-pay-bill.service";

import showSwal from "../../mixins/showSwal.js";

export default {
  name: "ShowPAckage",
  components: {
    DefaultInfoCard,
  },

  mounted() {
    this.getData();
    this.getMonthData();
  },

  data() {
      return {
          sortKey: '',
          sortOrder: 'asc',
          data: [],
          month_data: [],
          filteredData: [],
          searchQuery: "",
          currentPage: 1,
          itemsPerPage: 20,
          showPopup:false,
          selected_month:'',
          selected_item:[],
          formData:{
            olt_pack:null,
            cycle_type:'',
            customer:'',
          },
          loading_pay_letter:false,
          pay_loading:false,
          invoice:null,
          showPaymentMethod:false
      };
  },

  computed: {
    firstIntegerValue() {
      // Extracting the first integer value using regular expression
      const match = this.selected_month.match(/\d+/);
      return match ? parseInt(match[0]) : null;
    }
  },

  methods: {
      async Pay(olt_pack,cycle_type){

        this.formData.olt_pack=olt_pack
        this.formData.cycle_type=cycle_type
        let user= JSON.parse(localStorage.getItem("user_data"));
        this.formData.customer =user.id
        if(this.formData.cycle_type == ''){
           showSwal.methods.showSwal({
                type: "error",
                message: 'Please Select a Month',
            });
        }

        const response = await payNow.generateInvoicePayNow(this.formData);

        if(response.invoice_number){
          this.invoice = response.invoice_number
          this.showPaymentMethod =true
          this.showPopup = false
        }else{
          showSwal.methods.showSwal({
                type: "error",
                message: 'Something is wrong',
            });
        }
        
      },
      async getPay(){    
        this.pay_loading = true
        let response = await pay.getPayBill(this.invoice)
        if(response){
          window.open(response.bkashURL);
        }
        this.showPaymentMethod =false
        
        this.pay_loading = false
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      popup_open(item){
        this.formData.olt_pack =item.id
        let user= JSON.parse(localStorage.getItem("user_data"));
        this.formData.customer =user.id
        this.selected_item=item
        this.showPopup = true
      },
      popup_close(){
        this.showPopup = false
        this.showPaymentMethod =false
        this.invoice = null
         this.formData.olt_pack =null
         this.formData.cycle_type =''
         this.selected_month=''
         this.selected_item=[]
         
      },
      async addToCart(){
        this.loading_pay_letter =true
        this.formData.cycle_type = this.selected_month
        try {
            await Invoice.generateInvoiceData(this.formData);
            showSwal.methods.showSwal({
                type: "success",
                message: "Invoice Generated Successfully!",
            });
            
            this.loading_pay_letter =false
            //location.reload()
            this.popup_close();
        } catch (err) {
          let errors = await err.response.data
          console.error("Error to Generate  Invoice:", errors.error);
          showSwal.methods.showSwal({
                type: "error",
                message: errors.error,
            });
          
          this.loading_pay_letter =false
        }
      },
      async getData() {
          const response = await packageList.getPackageListData();
          this.data = response;
          this.filteredData = response;
      },
      async getMonthData() {
          const response = await MonthList.getMonthListData();
          this.month_data = response;
      },
  }
};
</script>

<style scoped>
/* The Modal (background) */
.modal { 
  display: block;/* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: #aaaaaa;
   /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow */
  max-width: 50%; /* Set max width */
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

</style>




