<template>
  <div class="fixed-plugin">
    <a
      class="px-3 py-2 fixed-plugin-button text-dark position-fixed"
      @click="toggle"
    >
      <i class="py-2 fa fa-cog"> </i>
    </a>
    <div class="shadow-lg card blur ">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="mt-4 float-end" @click="toggle">
          <button class="p-0 btn btn-link text-success fixed-plugin-close-button">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <hr class="my-1 horizontal dark" />
      <div class="pt-0 card-body pt-sm-3">
        <div class="card-body dark" >
          <h5 class="card-title">Contact Information</h5>
          <ul class="list-unstyled custom-list-style" >
            <li><a class="text-secondary" :href="`tel:${SupportData[0]?.phone_number}`"><i class="fa fa-phone mx-3"></i>{{SupportData[0]?.phone_number}}</a></li>
            <li><a class="text-secondary" :href="`mailto:${SupportData[0]?.maill_addres}`"><i class="fa fa-envelope mx-3"></i>{{SupportData[0]?.maill_addres}}</a></li>
            <li><a class="text-secondary" :href="SupportData[0]?.whtapp" target="_blank"><i class="fa fa-whatsapp mx-3"></i> Click here to chat</a></li>
          </ul>
        </div>
        <hr class="my-1 horizontal dark" />
        <div  class="pt-0 card-body pt-sm-3">
          <h5 class="card-title">Other Product List</h5>
          <div class="card-body dark"  v-for="(product, index) in ProductListData" :key="index">
              <div>
                <a href="#"  @click.prevent="openLink(product.target_link)">
                <img :src="product.product_photo"  alt="Product Image">
                <div >
                  <h5 >{{ product.product_name }}</h5>
                  <p >{{ product.description }}</p>
                </div>
                </a>
              </div>
            </div>
        </div>
         
      </div>
    </div>
  </div>
</template>

<script>
import others from ".././services/others.service";
export default {
  name: "configurator",
  props: ["toggle"],
  data() {
    return {
      ProductListData:[],
       SupportData:[]
    };
  },
  methods: {
     async getOthersData() {
      try {
        this.ProductListData = await others.getOthersProductListData();
        this.SupportData = await others.getOthersSupportData();
      } catch (error) {
        console.error('Error fetching others data:', error);
      }
    },
  },

  
  async mounted() {
            this.getOthersData();
        },
  
};
</script>
